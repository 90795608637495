/*******************************************************************************
 * Implement Gatsby's Browser APIs in this file.
 *
 * @see: https://www.gatsbyjs.com/docs/browser-apis/
 * 
 ******************************************************************************/

// Import my CSS file with tailwind library.
import "./src/styles/global.css";


// Fix Font Awesome Icons Flashing Wrong Size in Gatsby.
require("@fortawesome/fontawesome-svg-core/styles.css");
const config = require("@fortawesome/fontawesome-svg-core");

config.autoAddCss = false;

// Offline support.
// To display a custom message once your service worker finds an update,
// you can use the onServiceWorkerUpdateReady browser API in your gatsby-browser.js file.
// The following code will display a confirm prompt asking the user 
// whether they would like to refresh the page when an update is found.

// export const onServiceWorkerUpdateReady = () => {
//     const answer = window.confirm(
//       `This application has been updated. ` +
//         `Reload to display the latest version?`
//     )
  
//     if (answer === true) {
//       window.location.reload()
//     };
//   };
