exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-thank-u-tsx": () => import("./../../../src/pages/contact/thank-u.tsx" /* webpackChunkName: "component---src-pages-contact-thank-u-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-shop-index-tsx": () => import("./../../../src/pages/shop/index.tsx" /* webpackChunkName: "component---src-pages-shop-index-tsx" */),
  "component---src-pages-shop-order-canceled-tsx": () => import("./../../../src/pages/shop/order-canceled.tsx" /* webpackChunkName: "component---src-pages-shop-order-canceled-tsx" */),
  "component---src-pages-shop-order-with-errors-tsx": () => import("./../../../src/pages/shop/order-with-errors.tsx" /* webpackChunkName: "component---src-pages-shop-order-with-errors-tsx" */),
  "component---src-pages-shop-thank-u-tsx": () => import("./../../../src/pages/shop/thank-u.tsx" /* webpackChunkName: "component---src-pages-shop-thank-u-tsx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-blog-adriatica-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/codebuild/output/src4267224965/src/gargallo/blog/adriatica/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-blog-adriatica-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-blog-greece-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/codebuild/output/src4267224965/src/gargallo/blog/greece/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-blog-greece-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-blog-mediterraneo-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/codebuild/output/src4267224965/src/gargallo/blog/mediterraneo/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-blog-mediterraneo-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-blog-oceano-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/codebuild/output/src4267224965/src/gargallo/blog/oceano/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-blog-oceano-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-blog-sahara-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/codebuild/output/src4267224965/src/gargallo/blog/sahara/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-blog-sahara-index-mdx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-blog-tyrrhenum-index-mdx": () => import("./../../../src/templates/blog-post-template.tsx?__contentFilePath=/codebuild/output/src4267224965/src/gargallo/blog/tyrrhenum/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-blog-tyrrhenum-index-mdx" */),
  "component---src-templates-shop-product-template-tsx": () => import("./../../../src/templates/shop-product-template.tsx" /* webpackChunkName: "component---src-templates-shop-product-template-tsx" */)
}

